<template>
  <div class="header">
    <header class="page-header">
        <div class="page-container">
            <div class="logo">
                <a href="./">
                    <img src="../assets/logo.png" alt="上海翌砻网络科技">
                </a>
            </div>
            <button class="menu-btn" @click="openMenu">
                <i class="fa fa-navicon"></i>
            </button>
        </div>
    </header>
    <div class="right-menu">
        <div class="menu-bg" @click="openMenu"></div>
        <div class="menu-area">
            <button @click="openMenu"><i class="fa fa-close"></i></button>
            <nav>
                <a href="./" :class="{selected: selectMenu == 1}">首页</a>
                <a href="./projects" :class="{selected: selectMenu == 2}">客户案例</a>
                <a href="./about" :class="{selected: selectMenu == 3}">关于</a>
            </nav>
        </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Header',
  props: {
    selectMenu: {
      type: Number,
      required: true
    }
  },
  created() {
  },
  methods: {
    openMenu() {
        if($(document.body).hasClass("open-right-menu")) {
          $(document.body).removeClass("open-right-menu");
        } else {
          $(document.body).addClass("open-right-menu");
        }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
