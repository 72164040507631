<template>
    <div>
        <Header :selectMenu="2"></Header>
        <Body>
            <div class="header-img">
              <div class="page-header-info">
                <h1>客户案例</h1>
              </div>
            </div>
            <div class="page-panel">
                <div class="page-container">
                    <div class="card-only card-orange">
                        <div class="info">
                            <strong>01</strong>
                            <h2>上海电缆研究所</h2>
                            <em>高端装备用特种电缆检测评估服务基地变配电工程新装1000kva及增容2850kva工程</em>
                            <p>上海电缆研究所是全国唯一的集电线电缆研究开发、工程设计、测试检验、信息服务于一体的研究机构。智电宝在上海电缆研究所项目中提供智能配电房、智能点检、智能运维等功能，保障了配电房的安全、高效、智能、稳定。</p>
                        </div>
                    </div>
                    <div class="card-only card-default">
                        <div class="info">
                            <strong>02</strong>
                            <h2>静安新业坊</h2>
                            <em>静安新业坊7300kva电力增容工程</em>
                            <p>静安新业坊是临港集团打造的全新媒体影视产业基地，是由原上海冶金矿山机械厂改建而来，因此部分设备可能存在老化的风险，在此基础上应用智电宝，大大降低了安全隐患，保证设备在良好的生命周期内正常运行。</p>
                        </div>
                    </div>
                    <div class="card-only card-gray">
                        <div class="info">
                            <strong>03</strong>
                            <h2>上海长伟锦磁工程塑料有限公司</h2>
                            <em>长伟锦磁扩建2500kva电力增容工程</em>
                            <p>上海长伟锦磁工程塑料有限公司是一家专业生产各类改性工程塑料的沪港合资公司 ,在增容工程中应用智电宝，大大提升了电力的使用效率，满足了安全、节能、环保的要求和指标。</p>
                        </div>
                    </div>
                    <div class="card-line">
                        <div class="item card-q-gray">
                            <strong>04</strong>
                            <h2>上海外国语大学</h2>
                            <em>上外（虹口校区）8000kva电力增容工程</em>
                            <p>上海外国语大学是中国著名的高等外语学府,在增容工程中应用了智电宝项目进行配电房的监控和管理。</p>
                        </div>
                        <div class="item card-s-gray">
                            <strong>05</strong>
                            <h2>上海高平护理院</h2>
                            <em>高平护理院配电房托管运维项目</em>
                            <p>上海高平护理院作为一家专业的护理医疗机构，对电力的稳定和安全有较高的要求，智电宝的智能点检、智能报警保障了配电房的平稳运行。</p>
                        </div>
                    </div>
                </div>
            </div>
        </Body>
        <Footer></Footer>
    </div>
</template>
<script>
    import Header from '../components/Header.vue';
    import Body from '../components/Body.vue';
    import Footer from '../components/Footer.vue';

    export default {
        name: 'Projects',
        components: {
            Header,
            Body,
            Footer
        },
        props: {
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .header-img {
        background-image: url("../assets/bg-2.png");
        background-repeat: no-repeat;
        background-position: top center;
        background-attachment: fixed;
        padding: 300px 0 160px;
    }

    .page-header-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .page-header-info h1{
        font-family: "Roboto", Arial, Tahoma, sans-serif;
        font-size: 40px;
        font-weight: 400;
        letter-spacing: 1px;
        font-style: italic;
        color: white;
        margin-bottom: 10px;
    }

    .page-header-info ul{
        display: flex;
    }
    .page-header-info ul li {
        display: flex;
        align-items: center;
        color: rgba(68, 68, 68, 0.6);
        font-size: 14px;
    }
    .page-header-info ul li a{
        color: rgba(68, 68, 68, 0.6);
    }
    .page-header-info ul li:after{
        content: "\f105";
        font-family: FontAwesome;
        margin-left: 30px;
        margin-right: 30px;
    }

    .page-header-info ul li:last-child:after{
        display: none;
    }

    .page-panel {
        padding: 50px 0;
        background-color: #091124;
    }

    .card-only {
        padding: 30px 30px 40px;
        margin-bottom: 15px;
    }

    .card-line {
        display: flex;
    }

    .card-line:last-child {
        margin-bottom: 0;
    }

    .card-line .item{
        flex: 1;
        margin-left: 15px;
        padding: 30px 30px 40px;
    }
    .card-line .item:first-child{
        margin-left: 0;
    }

    .card-only .info {
        width: 58%;
        margin: 0 1% 40px;
        margin-bottom: 20px;
    }

    .card-only strong, .card-line .item strong{
        font-family: 'Roboto',Arial,Tahoma,sans-serif;
        font-size: 70px;
        line-height: 70px;
        font-weight: 100;
        margin-bottom: 15px;
        display: block;
    }
    .card-only h2, .card-line .item h2{
        font-size: 30px;
        line-height: 34px;
        font-weight: 300;
        letter-spacing: 0px;
        font-family: "Roboto", Arial, Tahoma, sans-serif;
        margin-bottom: 15px;
        display: block;
    }
    .card-only em, .card-line .item em{
        font-size: 15px;
        line-height: 25px;
        font-weight: 700;
        letter-spacing: 0px;
        font-family: "Roboto", Arial, Tahoma, sans-serif;
        margin-bottom: 15px;
        display: block;
        font-style: normal;
    }
    .card-only p, .card-line .item p{
        font-size: 15px;
        line-height: 25px;
        font-weight: 700;
        letter-spacing: 0px;
        font-family: "Roboto", Arial, Tahoma, sans-serif;
    }

    .card-orange {
        background-color: #f96c26;
    }
    .card-orange strong{
        color: #fcb693;
    }
    .card-orange h2{
        color: #fff;
    }
    .card-orange em{
        color: #fff;
    }
    .card-orange p{
        color: #fdd3be;
    }

    .card-default {
        border: 2px solid #6c7280;
        box-sizing: border-box;
    }
    .card-default strong{
        color: #a1a5ac;
    }
    .card-default h2{
        color: #fff;
    }
    .card-default em{
        color: #fff;
    }
    .card-default p{
        color: #fff;
    }

    .card-gray {
        background-color: #9198a5;
    }
    .card-gray strong{
        color: #646a77;
    }
    .card-gray h2{
        color: #fff;
    }
    .card-gray em{
        color: #fff;
    }
    .card-gray p{
        color: #fff;
    }

    .card-q-gray {
        background-color: #b4b8c1;
    }
    .card-q-gray strong{
        color: #646a77;
    }
    .card-q-gray h2{
        color: #fff;
    }
    .card-q-gray em{
        color: #fff;
    }
    .card-q-gray p{
        color: #fff;
    }

    .card-s-gray {
        background-color: #424959;
    }
    .card-s-gray strong{
        color: #a1a5ac;
    }
    .card-s-gray h2{
        color: #fff;
    }
    .card-s-gray em{
        color: #fff;
    }
    .card-s-gray p{
        color: #fff;
    }

    @media only screen and (max-width: 767px) {
        .card-only .info {
            width: 100%;
        }
        .card-line {
            flex-direction: column;
        }
        .card-line .item{
            margin: 0 !important;
            margin-bottom: 15px !important;
        }

        .card-line .item:last-child{
            margin-bottom: 0 !important;
        }
    }
</style>