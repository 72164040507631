<template>
  <div>
    <Header :selectMenu="1"></Header>
    <Body>
      <div class="switch-img-msg">
        <button class="per-btn"><i class="fa fa-angle-left"></i></button>
        <ul class="switch-list">
            <li>
                <p>智能电气 <br> 解决方案提供商 </p>
            </li>
            <li>
                <p>了解智能电气的 <br> 惠心与匠心</p>
            </li>
        </ul>
        <button class="nxt-btn"><i class="fa fa-angle-right"></i></button>
      </div>
      <div class="we-power">
        <div class="page-container">
            <header class="panel-area-header">我们的能力</header>
            <div class="group-line">
                <div class="item one-item">
                    <strong>01</strong>
                    <h2>智能化移动装接系统</h2>
                    <p>移动装接系统管理，充分利用移动设备和互联网等先进工具管理移动装接工作中用电客户、供电企业以及供电企业各个职能部门、施工团队之间的交互流程和业务数据，通过网络实现数据共享，解决数据的一致性问题，保证提供信息的准确和完整性，并利用计算机对这些数据进行统计分析，为管理人员提供必要的信息支持与决策支持，从而减轻管理人员的工作负担，提高工作效率和管理水平。同时，利用微信公众号等方式，为用户提供信息交互和项目参与互动，提高沟通成效和用户满意度。</p>
                </div>
                <div class="item three-item">
                    <strong>03</strong>
                    <h2>线损管理系统</h2>
                    <p>线损管理系统以EDCS周线损相关数据为源数据，结合线损消缺的现场工作模式，涵盖基础数据维护、消缺任务管理、缺陷问题闭环处理、统计分析以及数据展示等几大功能模块。</p>
                </div>
            </div>
            <div class="group-line">
                <div class="item two-item">
                    <strong>02</strong>
                    <h2>智电宝™EDCS管理系统</h2>
                    <p>智电宝™EDCS管理系统提供用电数据、用电计算、用电设施、用电管理，以及人工智能服务、精准定制基于场景的行业解决方案。“互联网+”和传统电气碰撞，新一轮科技革命和产业变革的驱动力量呼啸而至。智电宝帮助传统企业向数字化转型，用人工智能技术对行业大数据进行智能化的分析，为企业创造利润，同时探索一条让数据为企业、让人工智能为企业创造价值的新道路。</p>
                </div>
                <div class="item four-item">
                    <strong>04</strong>
                    <h2>智能用电咨询服务</h2>
                    <p>凭借我们在行业30多年累积的电力管理经验，能够为客户提供全方位的电力安全、节能、施工等解决方案，为客户提供完善用电咨询服务。</p>
                </div>
            </div>
        </div>
      </div>
      <div class="page-container page-panel">
          <header class="panel-area-header">我们的身份</header>
          <div class="card-area">
              <div class="info">
                  <strong>05</strong>
                  <h2>翌砻网络科技</h2>
                  <p>上海翌砻网络科技公司是一家专注于电力物联网服务及移动互联网产品开发的企业。公司核心团队由业内领军企业的资深人士组成，拥有丰富的研发、运维经验和对电力行业独到的见解。其核心产品“智电宝”，致力于运用最前沿的互联网技术，秉承科技改变生活，智慧引领未来的理念，打造智慧电力、智慧生活的智慧王国。</p>
                  <a href="./about">READ MORE</a>
              </div>
          </div>
          <p class="phone-info">
              <i class="fa fa-phone"></i> | 智电宝 TEL: 13816495044 Mail: chenfeng@91yilong.cn
          </p>
      </div>
    </Body>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Body from '../components/Body.vue';
import Footer from '../components/Footer.vue';
import $ from 'jquery';

export default {
  name: 'Index',
  components: {
    Header,
    Body,
    Footer
  },
  props: {
    msg: String
  },
  mounted() {
    $(".switch-list > li:first").addClass("selected");

    function next() {
        let li = $(".switch-list > li");
        let index = li.index($(".switch-list > li.selected"));
        $(".switch-list > li").removeClass("selected").eq(index + 1 >= li.length ? 0 : index + 1).addClass("selected");
    }

    setInterval(() => {
        next();
    }, 5000);

    $(".switch-img-msg .per-btn").click(function() {
        let li = $(".switch-list > li");
        let index = li.index($(".switch-list > li.selected"));
        $(".switch-list > li").removeClass("selected").eq(index - 1 < 0 ? li.length - 1 : index - 1).addClass("selected");
    });
    $(".switch-img-msg .nxt-btn").click(function() {
        next();
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .switch-list li:nth-child(1) {
    background-image: url('../assets/pylon.jpg');
  }
  .switch-list li:nth-child(2) {
    background-image: url('../assets/Electrician-assembling-industrial-electric-cabinet.jpg');
  }

  .switch-img-msg {
      height: calc(100vh);
      background-color: #ddd;
      position: relative;
  }

  .switch-img-msg:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      height: 60px;
    background: linear-gradient(rgba(9, 17, 36, 0), rgba(9, 17, 36, .1), rgba(9, 17, 36, .5), rgba(9, 17, 36, 1));
  }

  .switch-img-msg .per-btn, .switch-img-msg .nxt-btn {
      position: absolute;
      top: 50%;
      margin-top: -20px;
      border: 0;
      border-radius: 100%;
      display: block;
      background: rgba(0,0,0,0.5);
      width: 40px;
      height: 40px;
      z-index: 3;
      cursor: pointer;
      color: #fff;
      font-size: 28px;
      opacity: 0;
      transition: opacity .3s;
  }

  .switch-img-msg:hover .per-btn, .switch-img-msg:hover .nxt-btn {
      opacity: 1;
  }

  .switch-img-msg .per-btn {
      left: 30px;
  }

  .switch-img-msg .nxt-btn {
      right: 30px;
  }

  .switch-img-msg .switch-list {
      position: relative;
      width: 100%;
      height: 100%;
  }

  .switch-img-msg .switch-list li {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      z-index: 1;
      transition: opacity 1.5s;
  }

  .switch-img-msg .switch-list li.selected {
      opacity: 1;
      z-index: 2;
  }

  .switch-img-msg .switch-list li:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(9, 17, 35, 0.7);
  }

  .switch-img-msg .switch-list li p {
      position: relative;
      color: rgba(255, 255, 255, 0.7);
      font-size: 4.8vw;
      font-weight: 300;
  }

  .we-power {
      padding-bottom: 25px;
      background-color: #091124;
      box-shadow: 0 0 20px 0 #091124;
  }

  .we-power .panel-area-header {
      position: relative;
      z-index: 4;
      margin-top: -50px;
      color: #f96c26;
  }
  .we-power .panel-area-header:before, .we-power .panel-area-header:after {
      background-color: rgba(255,255,255,.3);
  }

  .we-power .group-line {
      display: flex;
      margin-bottom: 15px;
  }

  .we-power .group-line .item {
      padding: 30px 30px 35px;
      box-sizing: border-box;
  }

  .we-power .group-line .item strong{
      box-sizing: border-box;
      display: block;
      font-size: 70px;
      line-height: 70px;
      font-weight: 100;
      font-family: 'Roboto',Arial,Tahoma,sans-serif;
      margin-bottom: 15px;
  }

  .we-power .group-line .item h2{
      font-size: 25px;
      line-height: 29px;
      font-weight: 300;
      letter-spacing: 0px;
      margin-bottom: 15px;
  }

  .we-power .group-line .item p {
      box-sizing: border-box;
      font-size:14px;
  }

  .we-power .group-line .item.one-item {
      flex: 1;
      margin-right: 15px;
      background-color: #f96c26;
  }
  .we-power .group-line .item.one-item strong{
      color: #fcb693;
  }
  .we-power .group-line .item.one-item h2{
      color: #fff;
  }
  .we-power .group-line .item.one-item p{
      color: #fdd3be;
  }

  .we-power .group-line .item.two-item {
      border: 2px solid #6c7280;
      margin-right: 15px;
      flex: 1;
  }
  .we-power .group-line .item.two-item strong{
      color: #a1a5ac;
  }
  .we-power .group-line .item.two-item h2{
      color: #fff;
  }
  .we-power .group-line .item.two-item p{
      color: #fff;
  }

  .we-power .group-line .item.three-item {
      width: 33.333%;
      background-color: #b4b8c1;
  }
  .we-power .group-line .item.three-item strong{
      color: #646a77;
  }
  .we-power .group-line .item.three-item h2{
      color: #161922;
  }
  .we-power .group-line .item.three-item p{
      color: #626262;
  }

  .we-power .group-line .item.four-item {
      width: 33.333%;
      background-color: #424959;
  }
  .we-power .group-line .item.four-item strong{
      color: #a1a5ac;
  }
  .we-power .group-line .item.four-item h2{
      color: #fff;
  }
  .we-power .group-line .item.four-item p{
      color: #fff;
  }

  .page-panel {
      padding-top: 70px;
      padding-bottom: 80px;
  }

  .card-area {
      padding: 30px 30px 35px;
      box-sizing: border-box;
      background-color: #ff6c00;
      background-image: url("../assets/home_detailing_columnbg4.png");
      background-repeat: no-repeat;
      background-position: right bottom;
  }

  .card-area .info{
      width: 58%;
      margin: 0 1% 40px;
      margin-bottom: 20px;
  }

  .card-area .info strong{
      box-sizing: border-box;
      display: block;
      font-size: 70px;
      line-height: 70px;
      font-weight: 100;
      font-family: 'Roboto',Arial,Tahoma,sans-serif;
      margin-bottom: 15px;
      color: #ffb680;
  }
  .card-area .info h2{
      font-size: 25px;
      line-height: 29px;
      font-weight: 300;
      letter-spacing: 0px;
      margin-bottom: 15px;
      color: #fff;
  }
  .card-area .info p{
      color: #ffffff;
      margin-bottom: 15px;
      font-size:14px;
  }

  .card-area .info a{
      font-size: 14px;
      line-height: 25px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #fff;
  }

  .phone-info {
      margin-top: 20px;
      border: 2px solid #6c7280;
      padding: 20px;
      display: inline-block;
      font-size: 21px;
      line-height: 25px;
      font-family: "Roboto", Arial, Tahoma, sans-serif;
  }

  @media only screen and (max-width: 767px) {
      .we-power .group-line {
          flex-direction: column;
      }
      .we-power .group-line .item {
          width: 100% !important;
          flex: none !important;
          margin: 0 !important;
          margin-top: 15px !important;
      }

      .we-power .group-line .item:first-child {
          margin-top: 0 !important;
      }

      .card-area .info {
          width: 100%;
      }

      .phone-info {
          display: block;
      }
  }
</style>
