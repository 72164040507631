<template>
  <div id="app">
    <router-view>
    </router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  created() {
  }
}
</script>

<style>
@import "./libs/font-awesome-4.7.0/css/font-awesome.min.css";
@import "./styles/basic.css";
@import "./styles/global-lg.css";
@import "./styles/global-md.css";
@import "./styles/global-sm.css";
@import "./styles/global-xs.css";
</style>
