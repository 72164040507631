<template>
    <div>
        <Header :selectMenu="3"></Header>
        <Body>
            <div class="header-img">
              <div class="page-header-info">
                <h1>关于我们</h1>
              </div>
            </div>
            <div class="page-container page-panel">
                <header class="panel-area-header">翌砻网络科技</header>
                <img class="about-img" src="../assets/home_detailing_about1.jpg" alt="">
                <div class="company-info-area">
                    <div class="left-area"></div>
                    <div class="right-area">
                        <h2>上海翌砻网络科技有限公司</h2>
                        <p>一家专注于电力物联网服务及移动互联网产品开发的企业。公司核心团队由业内领军企业的资深人士组成，拥有丰富的研发、运维经验和对电力行业独到的见解。其核心产品“智电宝”，致力于运用最前沿的互联网技术，秉承科技改变生活，智慧引领未来的理念，打造智慧电力、智慧生活的智慧王国。</p>
                        <blockquote>
                            <h3>智电宝到底是什么？</h3>
                            <p>智电宝提供用电数据、用电计算、用电设施、用电管理，以及人工智能服务、精准定制基于 场景的行业解决方案。</p>
                            <h3>用来干什么的/解决行业什么问题？</h3>
                            <p>智电宝通过全面解析用户需求，深入研究国家用电政策，深耕电力三十余年累积的经验，对行业痛点如指诸掌，为各行业用户提供安全、智慧、专业、高效、节能的全方位一站式用电解决方案。结合最新的互联网技术、云平台架构以及人工智能服务，借助“移动监控”、“智慧点检”、“智能预警”等功能，完善的事件管理体系和运维管理体系，解决电力用户普遍存在的用电不安全、效率低下、能源浪费、没有点检或点检不合理产生的安全隐患等问题。</p>
                            <h3>核心价值？</h3>
                            <p>“互联网+”和传统电气碰撞，新一轮科技革命和产业变革的驱动力量呼啸而至。智电宝帮助传统企业向数字化转型，用人工智能技术对行业大数据进行智能化的分析，为企业创造利润，同时探索一条让数据为企业、让人工智能为企业创造价值的新道路。</p>
                        </blockquote>
                    </div>
                </div>
            </div>
        </Body>
        <Footer></Footer>
    </div>
</template>
<script>
    import Header from '../components/Header.vue';
    import Body from '../components/Body.vue';
    import Footer from '../components/Footer.vue';

    export default {
        name: 'About',
        components: {
            Header,
            Body,
            Footer
        },
        props: {
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .header-img {
        background-image: url("../assets/bg-2.png");
        background-repeat: no-repeat;
        background-position: top center;
        background-attachment: fixed;
        padding: 300px 0 160px;
    }

    .page-header-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .page-header-info h1{
        font-family: "Roboto", Arial, Tahoma, sans-serif;
        font-size: 40px;
        font-weight: 400;
        letter-spacing: 1px;
        font-style: italic;
        color: white;
        margin-bottom: 10px;
    }

    .page-panel {
        padding-top: 90px;
        padding-bottom: 50px;
    }

    .about-img {
        display: block;
        margin: auto;
        width: 100%;
    }

    .company-info-area {
        display: flex;
    }

    .company-info-area .left-area{
        width: 50%;
        background-color: rgb(249, 108, 38);
        background-image: url("../assets/home_detailing_wrapbg2.png");
        background-repeat: no-repeat;
        background-position: center bottom;
    }

    .company-info-area .right-area{
        flex: 1;
        padding: 30px 30px 15px;
        background-color: rgb(243, 243, 243);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .company-info-area .right-area > h2{
        color: #f96c26;
        font-size: 30px;
        line-height: 34px;
        font-weight: 300;
        letter-spacing: 0px;
        font-family: "Roboto", Arial, Tahoma, sans-serif;
        margin-bottom: 15px;
    }

    .company-info-area .right-area > p{
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        letter-spacing: 0px;
        font-family: "Roboto", Arial, Tahoma, sans-serif;
        margin: 0 0 15px;
        color:rgb(98, 98, 98);
    }

    .company-info-area .right-area > blockquote{
        border-left: 1px solid #f96c26;
        padding: 15px 0 15px 35px;
    }

    .company-info-area .right-area > blockquote h3{
        color: #f96c26;
        font-size: 21px;
        line-height: 25px;
        font-weight: 500;
        letter-spacing: 0px;
        font-family: "Roboto", Arial, Tahoma, sans-serif;
        margin-bottom: 15px;
    }
    .company-info-area .right-area > blockquote p{
        font-size: 14px;
        color:rgb(98, 98, 98);
        margin: 0 0 15px;
    }

    @media only screen and (max-width: 767px) {
        .company-info-area {
            flex-direction: column;
        }

        .company-info-area .left-area{
            flex: none;
            width: 100%;
            height: 680px;
        }
    }
</style>