<template>
  <div class="footer">
    <footer class="page-footer">
        <div class="page-container">
            <div class="go-top-btn">
                <button @click="goPageTop"><i class="fa fa-angle-up"></i></button>
            </div>
            <p>© 2022 上海翌砻网络科技. All Rights Reserved.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;陈锋&nbsp;&nbsp;T:&nbsp;13816495044&nbsp;&nbsp;Email:&nbsp;chenfeng@91yilong.cn</p>
            <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备18014479号-1</a>
            <br/>
            <a href="https://www.cnzz.com/stat/website.php?web_id=1278134520" target="_blank" title="站长统计"><img border="0" hspace="0" vspace="0" src="http://icon.cnzz.com/img/pic.gif"></a>
        </div>
    </footer>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Footer',
  methods: {
    goPageTop() {
      $("html").animate({
        scrollTop: 0
      }, 300);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .beian {
    color: white;
    font-size: 14px;
  }
</style>
